/* CreatePost.css */

.create-post-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-post-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333333;
}

.create-post-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555555;
}

.form-group input[type='text'],
.form-group textarea,
.form-group input[type='file'] {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input[type='text']:focus,
.form-group textarea:focus,
.form-group input[type='file']:focus {
  border-color: #0066cc;
  outline: none;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  padding: 12px 20px;
  background-color: #0066cc;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #004999;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 반응형 디자인 */
@media (max-width: 600px) {
  .create-post-container {
    padding: 15px 20px;
  }

  .create-post-container h2 {
    font-size: 24px;
  }

  .submit-button {
    font-size: 16px;
    padding: 10px 16px;
  }
}
