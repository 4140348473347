.container {
  width: 95%; /* 가로의 95% */
  /* height: 96vh; */
  margin: 2.5% auto; /* 가로와 세로 양옆에 2.5%씩 여백 설정 */
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column; /* 자식 요소를 세로로 배치 */
  margin-bottom: 15px;
}

.form-group label {
  width: 100%; /* 레이블의 너비를 100%로 설정 */
  margin-bottom: 5px; /* 레이블과 input 사이의 여백 */
}

.form-group input[type='text'],
.form-group input[type='password'],
.form-group select {
  width: 90%; /* Input Field를 100%로 설정하여 가로폭 채움 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-group .email-input-group {
  display: flex;
  align-items: center;
  width: 100%; /* 이메일 그룹도 Input Field와 동일하게 100%로 설정 */
}

.email-group .email-input-group input[type='text'] {
  width: 50%; /* 이메일 입력 필드의 너비 */
}

.email-group .email-input-group select,
.email-group .email-input-group input[type='text']:last-child {
  width: 45%; /* 도메인 선택과 입력 필드의 너비 */
}

.email-group .email-input-group span {
  margin: 0 5px;
}

.subscription-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.registration-form button {
  width: 50%;
  padding: 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto; /* 가로 중앙 정렬 */
  margin-top: 50px;
  margin-bottom: 20px;
}

.registration-form button:hover {
  background-color: #45a049;
}

.small {
  margin-top: 5px;
  color: #666;
  font-size: 12px; /* 텍스트 크기를 조금 더 키움 */
}

.error {
  color: red;
  font-size: 12px; /* 오류 텍스트 크기도 조정 */
}
