.contact-page {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; Viewport 전체 높이를 사용하여 가운데 정렬 */
}

.contact-container {
  width: 75%;
  max-width: 600px;
  padding: 20px;
  /* background-color: #eeeeee; */
  border-radius: 12px; /* 둥근 모서리 */
  text-align: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  margin-top: 10%;
}

.contact-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-container p {
  margin-bottom: 15px;
  font-size: 16px;
}

.contact-container a {
  color: #007bff;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}
