.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.news-container h1 {
  text-align: center;
}

.board {
  margin-top: 20px;
}

.board_list {
  margin-top: 20px;
  border-bottom: 1px solid #ddd; /* 구분선 추가 */
}

.board_list table tr:last-child {
  border-bottom: none; /* 마지막 행에는 구분선을 추가하지 않음 */
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 10px; /* 행 사이에 10px 간격 추가 */
}

th,
q td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid black;
  background-color: #f4f4f4;
}

th {
  text-align: center; /* th 텍스트 가운데 정렬 */
}

td.no,
td.time,
td.readNum {
  text-align: center;
}

td.title a {
  text-decoration: none;
  color: black;
}

td.title a:hover {
  text-decoration: underline;
}

.list_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  text-align: center;
}

.pagination {
  flex: 1;
  text-align: center;
}

.pagination a {
  margin: 0 5px;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
}

.pagination a.active {
  font-weight: bold;
  color: #0056b3;
}

.pagination a:hover {
  text-decoration: underline;
}

.news-form {
  margin-top: 20px;
}

.news-form > div {
  margin-bottom: 15px;
}

.news-form label {
  display: block;
  margin-bottom: 5px;
}

.news-form input[type='text'],
.news-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.news-form textarea {
  height: 100px;
}

.news-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.news-form button:hover {
  background-color: #0056b3;
}

.write-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
}

.write-button:hover {
  background-color: #0056b3;
}
