.album-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.album-item {
  margin-bottom: 20px;
  cursor: pointer;
}

.album-image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.album-image {
  width: 100%;
  height: auto;
}

.album-details {
  padding: 10px 0;
}

.album-title {
  font-size: 18px;
  font-weight: bold;
}

.album-description {
  font-size: 14px;
  color: #555;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button:hover {
  background-color: #0056b3;
}

.album-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.album-form div {
  display: flex;
  flex-direction: column;
}

.album-form label {
  margin-bottom: 5px;
}

.album-form input,
.album-form textarea {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.album-form button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.album-form button[type='submit'] {
  background-color: #007bff;
  color: white;
}

.album-form button[type='submit']:hover {
  background-color: #0056b3;
}

.album-form button[type='button'] {
  background-color: #6c757d;
  color: white;
}

.album-form button[type='button']:hover {
  background-color: #5a6268;
}

.album-images {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 이미지 사이의 간격 */
}

.album-images img {
  width: 100%; /* 이미지를 부모 컨테이너의 전체 너비로 조절 */
  height: auto; /* 이미지 비율 유지 */
  object-fit: cover; /* 이미지의 잘린 부분이 생기지 않도록 설정 */
}
