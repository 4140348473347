.container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-content {
  whie-space: pre-line; /* 줄바꿈을 유지하기 위한 CSS */
}
