.home-container {
  width: 100%;
  /* height: 100vh; 뷰포트 전체 높이를 차지하도록 설정 */
  overflow: hidden; /* 필요에 따라 오버플로우 숨김 처리 */
  position: relative; /* 요소들을 위치시키기 위해 상대 위치 사용 */
  /* background-color: #c4e8bc; */
  background-color: #ffffff;
  padding-top: 5%;
}

.links-container {
  text-align: center; /* 링크를 중앙에 배치 */
  margin-bottom: 20px; /* 로고와 링크 사이의 간격 */
}

.links-container a {
  margin: 0 15px; /* 링크 사이의 간격 설정 */
  text-decoration: none; /* 밑줄 제거 */
  color: #555; /* 텍스트 색상 */
  font-size: 1rem;
  font-weight: bold;
}

.links-container a:hover {
  color: #82cbc4; /* 호버 시 텍스트 색상 변경 */
}

.logo {
  /* width: 100vw;
  height: 100vh;
  object-fit: cover; */
  width: 70%; /* 화면의 70% 너비를 차지하도록 설정 */
  max-width: 600px;
  height: auto; /* 이미지의 원본 비율을 유지하면서 크기 조절 */
  display: block; /* 중앙 정렬을 위해 block으로 설정 */
  margin: 0 auto; /* 좌우 중앙 정렬 */
  margin-top: 10%;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px; */
}

.info-container {
  width: 75%;
  max-width: 600px;
  padding: 20px;
  background-color: #eeeeee; /* 배경색 */
  border-radius: 12px; /* 둥근 모서리 */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%); /* 수평 가운데 정렬 */
}

.info-container h1 {
  margin-top: 0;
  font-size: 2rem;
  color: #333;
}

.info-container p {
  margin: 20px 0;
  font-size: 1rem;
  color: #555;
}

.register-button {
  padding: 10px 20px;
  background-color: #82cbc4; /* 버튼 배경색 */
  color: white;
  border: none;
  border-radius: 24px; /* 둥근 모서리 */
  cursor: pointer;
  font-size: 1rem;
}

.register-button:hover {
  background-color: #6bb5ae;
}
